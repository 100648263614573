import styled from 'styled-components';

export const SignPageWrapper = styled.div`
  min-height: calc(100vh - 80px);
  position: relative;
  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 70%;
    bottom: 0;
    margin-top: 0%;
    margin-left: -10%;
    z-index: -1;
    background-image: radial-gradient(
      circle,
      var(--indigo) 0%,
      var(--indigo) 5%,
      var(--blue-background-extra-light) 5%,
      var(--blue-background-extra-light) 20%,
      var(--indigo) 20%,
      var(--indigo) 35%,
      var(--blue-background-extra-light) 35%,
      var(--blue-background-extra-light) 50%,
      var(--indigo) 50%,
      var(--indigo) 65%,
      var(--white) 65%,
      var(--white) 80%,
      var(--white) 80%,
      var(--white) 100%
    );
  }
  .inner-wrapper {
    ${'' /* min-height: calc(100vh - 53px); */}
    display: grid;
    grid-template-columns: auto 50%;
    grid-gap: 1.5rem;
    height: 100%;
    .template-wrapper {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.lg}) {
    &::before {
      content: none;
    }
    .inner-wrapper {
      display: block;
      .sign-template {
        display: none;
      }
    }
  }
`;

export const LoginWrapper = styled.div`
  ${'' /* background-image: url(${bannerImg}); */}
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 80vh;

  @media only screen and (max-width: 1440px) {
    min-height: 100vh;
  }
  @media only screen and (max-width: 772px) {
    background-position: left top;
  }
`;

export const LoginFormWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  ${'' /* width: 100vw; */}
  width: 100%;
  min-height: 85vh;

  .app-login {
    z-index: 1;
    width: 100%;
    max-width: 500px;
    padding: 2.5rem 3rem;
    padding-left: 1.5rem;
    background: white;
    border-radius: 4px;
    @media (max-width: 900px) {
      width: 65vw;
      padding: 2rem 1.5rem;
    }
    @media (max-width: 574px) {
      width: 80vw;
      padding: 2rem 1.5rem;
    }
    .social-login-buttons {
      padding: 1.5rem 1rem;
      button {
        font-size: 300;
        width: 100%;
        padding: 0;
        margin: 0;
        text-align: center;
        display: grid;
        grid-template-columns: 48px auto;
        align-items: center;
        border: none;
        outline: none;
        color: var(--white);
        background-color: var(--blue);
        font-family: var(--font-base);
        border-radius: var(--border-radius);
        &:hover {
          background-color: var(--blue-hover);
        }
        .social-icon {
          color: var(--blue);
          background-color: var(--white);
          border-radius: var(--border-radius);
          height: 48px;
          width: 48px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid var(--blue);
          border-right: 0;
        }
        .social-text {
          padding: 0 1.25rem;
        }
      }
    }
    .auth-separator {
      text-align: center;
      padding: 0.25rem;
      color: var(--muted-strong);
    }
    .login-title {
      padding: 0rem 1rem 1.5rem;
      font-size: 0.875rem;
      font-weight: 700;
      text-transform: uppercase;
    }
    form {
      label {
        font-size: 0.65rem;
        font-weight: 500;
        text-transform: uppercase;
        min-width: 100px;
        padding: 1.5rem 0rem;
      }
      .field-wrapper {
        padding: 0.5rem 1rem;
        .app-input {
          width: 100%;
        }
        .label {
          font-size: 0.875rem;
          font-weight: 500;
          color: var(--muted-strong);
          min-width: 100px;
          padding: 0.875rem 0rem;
          &.header {
            font-size: 1rem;
            text-align: center;
            padding: 1rem 0rem;
          }
        }
        input {
          font-size: 0.875rem;
          color: var(--muted-strong);
          border: none;
          background-color: var(--blue-background-extra-light);
          padding: 1rem 1.5rem;
          border-radius: 2px;
        }
        button {
          font-size: 300;
          width: 100%;
          padding: 0;
          margin: 0;
          text-align: center;
          align-items: center;
          border: none;
          outline: none;
          color: var(--white);
          background-color: var(--blue);
          font-family: var(--font-base);
          border-radius: var(--border-radius);
          padding: 1.25rem;
          &:hover {
            background-color: var(--blue-hover);
          }
        }
        &.error {
          text-align: center;
          background-color: var(--danger-background);
        }
        &.submit {
          padding-top: 1rem;
        }
      }
    }
    .forgot-password-switch {
      text-align: right;
      color: var(--muted-strong);
      font-size: 0.875rem;
      padding: 0.5rem;
      text-decoration: underline;
    }
    .login-form-switch {
      text-align: center;
      color: var(--muted-strong);
      font-size: 0.875rem;
      padding: 0.5rem;
      a {
        text-decoration: underline;
      }
    }
  }
`;

export const Warning = styled.div`
  padding: 0.25rem 0.5rem;
  font-size: 0.6rem;
  font-weight: 400;
  color: var(--muted-strong);
  text-transform: uppercase;
  text-align: ${({ centered }) => (centered ? 'center' : 'inherit')};
`;
